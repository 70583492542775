import {Info,socialLinks} from '../User';


const Footer=()=>{
   
          const socialIcons=socialLinks.map((sociallink,index)=>{
          return <a href={`${sociallink.links}`} target="_blank" className="font-mono text-lg  
          hover:text-primaryColor hover:scale-105 transition-transform duration-300 
            ease-in-out">
         <sociallink.icon size={30} stroke={1.5}  />
        </a>
 
      })

 return(
   
    <div className="mt-20 mb-10 font-mono text-center flex flex-col items-center">   
        <div className='md-mx:flex hidden gap-6 text-textColor sm-mx:gap-4 '>{socialIcons}</div>
       <div className="text-xl md-mx:text-2xl text-primaryColor font-mono font-semibold mt-3">{Info.name}</div>
        <div className='text-textColor text-lg  md-mx:text-lg sm-mx:text-base xs-mx:text-sm xs-mx:flex flex-col items-center sm-mx:mt-3 xs-mx:mt-3'>
      Copyright &copy; {new Date().getFullYear()}  <span className='xs-mx:hidden'>| </span><span>All Rights Reserved</span></div>

   <p className='text-textColor text-xs xl:text-sm'>Designed & Built by <span className='text-primaryColor'> Shubham Mishra </span></p>
  </div>
  )

}

export default Footer;


