import certificate1 from '../images/certificate1.jpg';
import certificate5 from '../images/certificate5.jpg';
import certificate7 from '../images/certificate7.jpg';
import Certificate2 from '../images/Certificate2.jpg';
import Certificate3 from '../images/Certificate3.jpg';
import certificate20 from "../images/certificate20.jpg";
import certificate30 from "../images/certificate30.png";
import certificate12 from "../images/certificate12.jpg";
import certificate6 from "../images/certificate6.jpg";


import GalleryCard from './GalleryCard';

function Gallery() {

 const data=[
  {
    id:1,
     img:certificate12,
  },
  {
   id:2,
    img:certificate5,
  },
  {
   id:3,
    img:certificate20,
  },
  {
    id:4,
     img:certificate7,
   },

  {
    id:5,
     img:Certificate2,
   },
  
  {
    id:6,
     img:Certificate3,
   },
     {
       id:7,
      img:certificate30,
     },
  {
    id:1,
    img:certificate1,
   },
   {
    id:8,
     img:certificate6,
  }


];

    return (
             <div className="px-16 my-10  lg:mx-20 font-mono md-mx:px-6" id="Gallery">
       <h1 className="text-4xl sm-mx:text-3xl xs-mx:text-2xl text-center mb-10 font-bold text-whiteColor ">
      Gallery</h1>
 
               <div className="justify-around flex flex-wrap  gap-4 md-mx:gap-2 md-mx:justify-between sm-mx:justify-center">
                 {
                data.map((project:any,index:number)=><GalleryCard key={index}  img={project.img}  />)
                 }             
                </div>
      </div>
    );
}

 export default Gallery;