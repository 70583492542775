import React, { useEffect } from 'react';
import Homepage from './Components/HomePage';
import { MantineProvider, Text } from '@mantine/core';
import '@mantine/core/styles.css';
import './App.css';
import { pdfjs } from 'react-pdf';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import { createTheme } from '@mantine/core';





  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
      'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
  ).toString();


const App=()=>{
    useEffect(()=>{
     AOS.init();
  },[]);

    const theme=createTheme({
       breakpoints:{
         'xs': '320px',
          'sm': '476px',
          'md': '640px',
          'bs': '768px',
           'lg': '900px',
            'xl': '1024',
            '2xl': '1200',

     },

       })


 return(
   <>
     
    <MantineProvider theme={theme}>
     <Homepage/>
    </MantineProvider>
     
  </>
 );

}

export default App;
