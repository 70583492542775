import ServiceCard from "./ServiceCard";
import { ServiceInfo } from "../User";


 const Services=()=>{
   return(
      <div  data-aos="fade-up" data-aos-duration="800" className="px-28 my-10 mb-5 lg:mx-20 font-mono md-mx:px-6" id="My Ventures">
       <h1 className="text-4xl sm-mx:text-3xl xs-mx:text-2xl text-center mb-10 font-bold text-whiteColor ">
       My Ventures</h1>
         <div className="justify-around flex flex-wrap flex-row-4 gap-2 md-mx:gap-2 md-mx:justify-between sm-mx:justify-center ">
             {
             ServiceInfo.map((project:any,index:number)=><ServiceCard key={index} title={project.title} desc={project.desc}
             image={project.image} live={project.live} link={project.link} video={project.video}  />)
           }      
      </div>
     </div>
  )         
 }

export default Services;


{/**/}