import lallu from "../images/lallu.jpg";
import { TbBrandGithub } from "react-icons/tb";
import { RxOpenInNewWindow } from "react-icons/rx";



 const Project=()=>{
 return(
   <section>
    <div  className="px-16 my-10  lg:mx-20 font-mono md-mx:px-6" id="Workshop">
       <h1 className="text-4xl sm-mx:text-3xl xs-mx:text-2xl text-center mb-10 font-bold text-whiteColor ">
       Workshop</h1>
  
       <div data-aos="fade-up" data-aos-duration="800" className="w-full xs:py-2 lg:py-7 sm:py-7 flex flex-col items-center justify-center gap-28 ">
          <div className="flex flex-col xl:flex-row lg:items-center  gap-4">
           <a className="w-full xl:w-1/2 lg:w-1/2 bs:w-1/2 md:w-1/2 h-auto relative group" 
          href="http://vedpath.co.in/" target="_blank">
              <div>
                <img src={lallu} className="w-full h-full object-contain rounded-md" alt="" />
     
                 </div>         
           </a>
           <div className="w-full xl:w-1/2 flex flex-col  gap-6 lgl:justify-between items-end text-right ">
             <p className="font-mono text-primaryColor xl:mt-10 text-sm tracking-wide">
            Featured Project
            </p>
           <p className="bg-[#112240] text-whiteColor  text-sm md:text-base border shadow-[0_0_10px_0_#64FFDA50] p-2 md:p-6 rounded-md font-normal text-justify">
        The resource persons for the workshops were Prof. B. Mohan Kumar, Vice-Chancellor, AUS,<span className="text-primaryColor"> Dr. Vivek Pathak, Director (IQAC)</span>, Dr. Sreejith Aravindakshan,
       Associate Professor, Department of Agricultural Science, AUS and Dr. D. Balasubramanium, Associate Professor, Department of Botany, AUS.Scholarly writing is an area in which the faculty members of Indian academic Institutions are seldom trained;
              most of them find it an intimidating challenge to prepare their manuscripts for high-quality journals Just like
            any other area of professional expertise
           
           
            </p>
               <ul className="text-xs md:text-sm font-mono tracking-wide flex gap-2 md:gap-5 justify-between text-primaryColor">
              <li>Vedpath</li>
              <li>Shivip</li>
              <li>Desert</li>
              <li>vercel</li>
                 </ul>
              <div className="inline-flex gap-4">
                  <TbBrandGithub  className="text-whiteColor hover:text-primaryColor" size={25} />
                      <RxOpenInNewWindow  className="text-whiteColor hover:text-primaryColor" size={25} />
                  
                 </div>
             </div>
            </div>              
        </div>
 
           
           

     </div>
    </section>
  )



}
export default Project;