import Header from "./Header";
import Mail from "./Mail";
import Social from "./Social";
import About from "./About";
import AcademicCredentials from "./Academia";
import Contact from "./Contact";
import Services from "./Services";
import Loader from "./Loader";
import { useEffect } from "react";
import { useState } from "react";
import { Toaster } from "react-hot-toast";
import WhatsNext from "./WhatsNext";
import Project from "./Project";
import Introduction from "./Introduction";
import Footer from "./Footer";
import Gallery from "./Gallery";



const Homepage=()=>{
const [loading,setLoading]=useState(true);
  
 useEffect(()=>{
   setTimeout(()=>{
      setLoading(false);
  },5000)
 
  },[])


return <div className={`min-h-[100vh] -${loading?"flex":""}  items-center overflow-hidden justify-center scrollbar scrollbar-track-textDark/20s scrollar-thumb-textDark/60s`}>
{  
 loading!==true?<>
      
    <Toaster/>
   <Header/> 
   <About/>
    <Introduction />
    <Services/>
   <AcademicCredentials/>
    <Gallery/>
      <Project/>
     <Contact/>
      <WhatsNext/>
     <Footer/>
   <Mail/>
   <Social/>
  
    </>:
  <Loader/>}

</div>

}

export default Homepage;

{/* @5@F74H4yLj%t_j    */}