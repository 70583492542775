import { IconHexagonLetterV } from '@tabler/icons-react';
import SideBar from './SideBar';
import { useMediaQuery } from '@mantine/hooks';
import {em} from "@mantine/core";
import { useEffect, useState } from 'react';
import {motion} from "framer-motion";

 const links=["About","My Ventures","Academia","Gallery","Workshop","Contact"]; 
 const navLinks=(col:Boolean,clicked:any)=>{
    const handledClick=()=>{
      if(clicked)clicked();
   }
   return links.map((link,index)=>{
    return <motion.a
             initial={{x:10, opacity: 0}}    
             animate={{x : 10, opacity: 1}}
             transition={{duration: 1, delay:0.9}}   
          key={index} onClick={handledClick} className={`${col?'flex flex-col items-center font-mono':''} text-textColor text-lg font-mono hover:text-primaryColor`}
       href={`#${link}`}>{link}</motion.a>

  })

  } 


 const Header=()=>{
 const isMobile = useMediaQuery(`(max-width: ${em(476)})`);
  const [show,setShow] =useState(true);
 const [lastScrollY,setLastScrollY]=useState(0);
 const [shadow,setShadow]=useState(false);

  const controlNavbar=()=>{
    if(window.scrollY>lastScrollY && window.scrollY>70)setShow(false);
   else setShow(true);
     if(window.scrollY>70)setShadow(true);
       else setShadow(false);

   setLastScrollY(window.scrollY);
 }
   useEffect(()=>{
     window.addEventListener('scroll',controlNavbar);
       return()=>window.removeEventListener('scroll',controlNavbar);

  })

  return(
    <nav  className={`flex ${show?"translate-y-0":"-translate-y-20"} ${shadow?"shadow-[0px_10px_30px_-10px_#020c1b] ":""} transition-transform duration-500 ease-in-out fixed w-full z-10 bg-bgColor h-[18vh] font-mono dm-mono-regular px-10 justify-between items-center`}>
      
         <IconHexagonLetterV className='z-10' size={isMobile?45:60} color="#64FFDA" stroke={1.25} />

      <div className="md:flex gap-8 hidden">
         {navLinks(false,null)} 
        </div>
      <SideBar/>
    </nav>
   




  )

}

export default Header;
export {navLinks};
