import vivek3 from "../images/vivek3.jpg";

 const Introduction=()=>{
   return(
     <div  className="px-16 my-10  lg:mx-20 font-mono md-mx:px-6" id="About">
       <h1 className="text-4xl sm-mx:text-3xl xs-mx:text-2xl text-center mb-10 font-bold text-whiteColor ">
       About Me</h1>
  
       <div data-aos="fade-up" data-aos-duration="800" className="w-full xs:py-2 lg:py-7 sm:py-7 flex flex-col items-center justify-center gap-28 ">
          <div className="flex flex-col xl:flex-row lg:items-center bs:items-center md:items-center gap-6">
           <a className="w-full xl:w-1/2 lg:w-1/2 bs:w-1/2 md:w-1/2 h-auto relative group " 
          href="http://shivip.co.in/" target="_blank">
              <div>
                <img src={vivek3} className="w-full h-full object-contain rounded-2xl" alt="" />
     
                 </div>        
           </a>
           <div className="w-full xl:w-1/2 flex flex-col gap-6 lgl:justify-between items-end text-right ">

           <p className="bg-[#112240] text-whiteColor  text-sm md:text-base border shadow-[0_0_10px_0_#64FFDA50] p-2 md:p-6 rounded-md font-normal text-justify">
             Dr.<span className="text-primaryColor text-xl">Vivek Pathak</span> is an accomplished academician, researcher and an Edupreneur, with a passion toward innovation and sustainability. He represented academics as well as the industry in his seventeen years of rich experience. 
      Graduated as an IT engineer and completing his MBA in Marketing, he also holds a doctorate (Ph.D.) in service quality management from National Institute of Technology Sichar.
       He has more than seventeen research papers published in ABDC, SCOPUS, WOS and other indexed journals. He is supervising five doctoral research scholars at present.
             
            </p>
           
             </div>
            </div>              
        </div>
 
           
           

     </div>
)
}

 export default Introduction;



{/* <section>
    <div data-aos="zoom-out-up" data-aos-duration="800"  className="px-16 my-10  lg:mx-20 font-mono md-mx:px-6" id="About">
       <h1 className="text-4xl sm-mx:text-3xl xs-mx:text-2xl text-center font-bold mb-3 text-whiteColor ">
        About Me</h1>
  
       <div data-aos="fade-up" data-aos-duration="800" className="w-full  flex flex-col items-center justify-center   ">
          <div className="flex flex-col xl:flex-row xl:gap-6 lg:gap-6 sm:gap-6 md:justify-center md:items-center lg:justify-center lg:items-center ">
           
             <div className="">
                <img src={About} className="w-[400px] h-[400px] md:w-[500px] md:h-[500px] rounded-md object-contain" alt="" />
               </div>
           <div className="w-full xl:w-1/2 flex flex-col gap-4 md:gap-5 sm:gap-5 lg:gap-5 lgl:justify-between xl:items-end xl:text-right lg:items-center xs:items-center xs:text-center xs:justify-center ">
             <p className="font-mono text-primaryColor text-2xl   lg:text-3xl tracking-wide">
            Dr. Vivek Pathak
            </p>
           <p className="bg-[#112240] text-sm md:text-base border shadow-[0_0_10px_0_#64FFDA50] p-2 md:p-6 rounded-md text-textGray text-justify">
               Dr. Vivek Pathak is an accomplished academician, researcher and an Edupreneur, with a passion toward innovation and sustainability. He represented academics as well as the industry in his seventeen years of rich experience. 
      Graduated as an IT engineer and completing his MBA in Marketing, he also holds a doctorate (Ph.D.) in service quality management from National Institute of Technology Sichar.
       He has more than seventeen research papers published in ABDC, SCOPUS, WOS and other indexed journals. He is supervising five doctoral research scholars at present.
             </p>
             
             </div>
            </div>              
        </div>
   </div>
 </section>  */}