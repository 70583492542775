import { Button , useMatches } from '@mantine/core';  

const WhatsNext=()=>{
   const btn = useMatches({
        xs:'xs',
        sm:'sm',
        md:'md',
        lg:'lg',

     })

  return(
        <section 
       className='max-w-contentContainer mx-auto xl:py-32 flex flex-col gap-4 items-center justify-center px-10 lg:py-5'>
        <p className='font-mono text-lg text-primaryColor font-semibold flex items-center tracking-wide'>
       What's Next?
        </p>
      <h2 className='font-mono text-3xl font-semibold text-whiteColor  xl:text-5xl xs:text-4xl sm:text-5xl '>Get In Touch</h2>
      <p className='mt-4 text-sm  text-center text-textColor xl:w-[600px] lg:w-[500px] md:w-[450px] sm:w-[400px] xs:w-[350px]'>
        Allthough I'm not currently looking for any new opportunities, my inbox
        is always open. Whether you have a question or just want to say hi, I'll
         try my best to get back to you!
      </p>
        <a href="mailto:drvivekpathak23@gmail.com" target="_blank">
       <Button   className="text-primaryColor mt-4 hover:scale-100 duration-150  !w-fit" variant="outline" size={btn} color='#64FFDA'>
          Say Hello 
            </Button>
        </a> 

      

   </section>
  )
}

 export default WhatsNext;

{/*   
 */}