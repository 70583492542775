import {Image,Text, Indicator,Modal, Badge, Group, Button,useMatches } from '@mantine/core';
import { ScrollArea } from '@mantine/core';


const GalleryModal=(props:any)=>{
 const btn = useMatches({
        xs:'xs',
        sm:'sm',
        md:'md',
        lg:'lg',

     })
   return(
   <Modal.Root scrollAreaComponent={ScrollArea.Autosize} size={btn} className='font-mono' opened={props.opened} onClose={props.close}>
        <Modal.Overlay />
        <Modal.Content className='!rounded-3xl'>
          <Modal.Header className='!bg-bgColor !border-primaryColor  border-2 border-b-0 !rounded-tl-3xl !rounded-tr-3xl'>
            
            <Modal.CloseButton size={btn} iconSize="30px" color="red" className='!bg-bgColor !text-textRed'  />
          </Modal.Header>
          <Modal.Body className='!bg-bgColor !pt-2 !border-primaryColor  border-2 border-t-0 !rounded-bl-3xl !rounded-br-3xl'>
         <Image
           className="!rounded-xl h-[400px] !object-cover !shadow-[0_0_5px_0_#64FFDA] sm-mx:h-[200px]"
          src={props.img}
          alt={props.img}
        />
      
 
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
  )





}

export default GalleryModal;