 import IconCloud from "./IconCloud";
 import { IconHexagon } from "@tabler/icons-react";
import { slugs } from "../User";

const Loader=()=> {
  return (
    <div className="relative flex min-h-[100vh] items-center justify-center 
       px-20 pb-20 pt-8 animate-[ping_5s_ease-in-out_1_3s] ">
      <IconCloud iconSlugs={slugs} />
         <IconHexagon className="absolute -z-10 animate-[spin_5s_linear_infinte]" size={120} color="#64FFDA" stroke={1.25}  />
       <div className="absolute text-6xl -z-10 font-mono text-primaryColor font-semibold">VP</div>
    </div>
  );
}

export default Loader;
