import {Info} from '../User';
import Typewriter from 'typewriter-effect';
import { Button, useMatches } from '@mantine/core';
import { IconDownload } from '@tabler/icons-react';
import { resolveModuleName } from 'typescript';
import Particles from './MagicUi/Partcles';
 import {motion} from "framer-motion";
import { LOADIPHLPAPI } from 'dns';
import { hasPointerEvents } from '@testing-library/user-event/dist/utils';
import { validateForm } from './Validation';
import image2 from '../images/image2.jpg';



 const About=()=>{
  const btn = useMatches({
        xs:'xs',
        sm:'sm',
        md:'md',
        lg:'lg',

     })
    return(
        <>
      <div data-aos="zoom-out-up" data-aos-duration="800"
       className="mt-28 flex relative overflow-hidden justify-around items-center font-mono
        px-10 py-10 sm-mx:px-4 xs-mx:px-2 xs-mx:py-4  h-fit lg-mx:just-between bs-mx:flex-wrap bs-mx:flex-col-reverse bs-mx:!overflow-visible bs-mx:gap-6
        md-mx:px-6" >
       <Particles
        className="absolute -z-20 inset-0"
        quantity={1000}
        ease={80}
        vx={.1}
         vy={.1}
        color="#64FFDA"
        refresh
      />
            <div className='bs:ml-10 bs:w-3/5 flex flex-col lg-mx:gap-3 bs-mx:items-center'>
          <div className='text-primaryColor text-3xl lg-mx:text-3xl  xs-mx:text-2xl xsm-mx:text-xl'>Hi, I am</div>
          <motion.div 
           initial={{y:10, opacity: 0}}    
            animate={{y: 0, opacity: 1}}
             transition={{duration: 0.5, delay:0.6}}        
        className='text-[4.25rem] font-extrabold text-whiteColor lg-mx:text-6xl sm-mx:text-5xl xs-mx:text-4xl xsm-mx:text-[35px]'>
         {Info.name}
            </motion.div>            
            <div className='text-whiteColor text-4xl flex font-semibold lg-mx:text-[27px] sm-mx:text-3xl xs-mx:text-xl xsm-mx:text-xs'>I'm&nbsp;<span className='text-primaryColor'>
  <Typewriter
  options={{
    strings: Info.stack,
    autoStart: true,
    loop: true,
  }}
/>
        </span></div>

               <div className='text-textColor  text-xl w-[90%] my-8 lg-mx:my-0 text-justify font-semibold lg-mx:text-base sm-mx:text-xl xs-mx:text-sm'>{Info.bio}</div>
             <Button component='a' href='DrVivek.pdf' download="resume.pdf"  className="text-primaryColor  !w-fit" variant="outline" rightSection={<IconDownload size={20} />}
         size={btn} color='#64FFDA'>
          Download 
            </Button>
              </div>

              <div className='h-fit flex justify-center items-center rounded-2xl bs:mr-10 w-fit' id="photo">
            <div className='w-[325px] h-[325px] lg-mx:w-64 lg-mx:h-64 xsm-mx:h-56 xsm-mx:w-56 md:w-70 md:h-70  items-center justify-center text-center'>
             <img src={image2} className='w-full h-full rounded-2xl' alt="profile" />
            </div>

           </div>      
    
         </div>
        
      </>
    )

 }

export default About;



/*
 const [dots, setDots] = useState<any>(null)
  
id="bg"
   import DOTS from 'vanta/src/vanta.dots';
 import { useState,useEffect } from 'react';



useEffect(() => {
    if (!dots) {
      setDots(DOTS({
        el: DOTS({
      el: "#bg",
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.00,
      minWidth: 200.00,
      scale: 1.00,
      scaleMobile: 1.00,
      color: 0x9620ff,
       color2: 0x2d20ff,
      backgroundColor:0x11224000,
       size: 2,
       spacing: 20.00
 })
      }))
    }
    return () => {
      if (dots) dots.destroy()
    }
  }, [])*/



{/* 
      */}