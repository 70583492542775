

// side me mail to horizontal me rakhna.

 const Mail=()=>{
 return(
     <div className="flex md-mx:hidden text-textColor fixed bottom-32 -right-48 md:-right-56 rotate-90 items-center gap-10 lg:-right-52 lg:bottom-24 md: ">
        <a href="mailto:drvivekpathak23@gmail.com" className="font-mono tracking-widest text-lg hover:text-primaryColor hover:translate-x-2 transition-transform duration-300 
        ease-in-out">
         contactme@vivekpathak.info
        </a>

      <hr className="border-[2px] rounded-full text-textColor bg-textColor border-textColor w-40"/>
    </div>



 )

}

export default Mail;
