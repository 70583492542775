import { Badge, Button, Card, Group, } from "@mantine/core";
import { Text,Image,Indicator } from '@mantine/core';
import FullServicesModal from "./FullServicesModal";
import { useDisclosure } from "@mantine/hooks";


const ServiceCard=(props:any)=>{

const [opened, {open,close}] = useDisclosure(false);
 
return(
      
        <div className="py-5 w-[37%] bs-mx:w-[44%] lg-mx:w-[46%] md-mx:w-[48%] sm-mx:w-[90%] xs-mx:w-full" data-aos="fade-up" data-aos-duration="800">
     <Card onClick={open} className="!bg-bgColor cursor-pointer transition-transform
        duration-300 ease-in-out hover:!scale-[1.02] mb-5 hover:!shadow-[0_0_10px_1px_#64FFDA80]
          !border-primaryColor border-2" shadow="lg" padding="sm" radius="lg" withBorder>
      <Card.Section className="p-3">
        <Image
           className="!rounded-xl !shadow-[0_0_5px_0_#64FFDA]"
          src={props.image}
          alt={props.image}
        />
      </Card.Section>
 
      <Group justify="space-between" mt="xs" mb="xs">
        <Text className="!text-2xl !font-bold gap-2 !text-whiteColor flex bs:tex-xl lg:text-xl items-center sm-mx:text-xl" fw={500}>{props.title}
        {props.live==="true" && <Badge className="!px-1" variant="outline" color="pink" rightSection={<Indicator className="!mr-0.5" color="pink" position="middle-end" size={7} processing></Indicator>}> Live</Badge>}
          </Text>
      </Group>
          
      <Text className="!text-justify !text-sm xs-mx:text-xs" lineClamp={5}  size="sm" c="dimmed" >
        {props.desc}
      </Text>

      <Button onClick={open} className="#64FFDA"  variant="outline" fullWidth mt="md" radius="md">
        Show More
      </Button>
    </Card>
     <FullServicesModal opened={opened} close={close} title={props.title} desc={props.desc}
             image={props.image} live={props.live} link={props.link} video={props.video} />
  </div>


 )

}

export default ServiceCard;