import {Image,Text, Indicator,Modal, Badge, Group, Button,useMatches } from '@mantine/core';
import { ScrollArea } from '@mantine/core';


const FullServicesModal=(props:any)=>{
 const btn = useMatches({
        xs:'xs',
        sm:'sm',
        md:'md',
        lg:'lg',

     })
   return(
   <Modal.Root scrollAreaComponent={ScrollArea.Autosize} size={btn} className='font-mono' opened={props.opened} onClose={props.close}>
        <Modal.Overlay />
        <Modal.Content className='!rounded-3xl'>
          <Modal.Header className='!bg-bgColor !border-primaryColor  border-2 border-b-0 !rounded-tl-3xl !rounded-tr-3xl'>
            <Modal.Title className='!text-3xl !text-whiteColor flex gap-3 font-bold'>{props.title}{props.live==="true" && <Badge variant="outline" color="pink"
          rightSection={<Indicator color="pink" position="middle-end" size={btn} processing></Indicator>}> Live</Badge>}</Modal.Title>
            <Modal.CloseButton size={btn} iconSize="30px" color="red" className='!bg-bgColor !text-textRed'  />
          </Modal.Header>
          <Modal.Body className='!bg-bgColor !pt-2 !border-primaryColor  border-2 border-t-0 !rounded-bl-3xl !rounded-br-3xl'>
         <Image
           className="rounded-xl h-[400px] !object-cover !shadow-[0_0_5px_0_#64FFDA] sm-mx:h-[200px]"
          src={props.image}
          alt={props.image}
        />
          <Text className="!text-justify" mt="md"   size={btn} c="dimmed" >
        {props.desc}
      </Text>
     <Group justify='space-between' mt={btn} className='sm-mx:inline-flex'>
       <a href={props.link} target="_blank" className='!w-[48%]'>
         <Button variant="outline" size={btn} color="#64FFDA"  mt="md" radius="md" >
              View More
           </Button>
         </a>

          <a href={props.video} target="_blank" className='!w-[48%]'>
         <Button className='!bg-primaryColor !text-bgColor' size={btn} color="#64FFDA"  mt="md" radius="md"  fullWidth  >
              Video
           </Button>
         </a>


         </Group>

          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
  )





}

export default FullServicesModal;