import { Text, Timeline, useMatches } from "@mantine/core";
import { IconBriefcase, IconGitPullRequest, IconGitCommit, IconMessageDots, IconBriefcaseFilled,  IconCheckbox } from '@tabler/icons-react';
import { AcademicInfo } from "../User";


  const TimeLineItem=(items:any)=>{
       
        return  items.map((item: any,index: number)=><Timeline.Item key={index} className="!pt-8" bullet=
          {<IconBriefcaseFilled className="text-bgColor sm-mx:px-1" size={20} />} >
              <div  data-aos="fade-up" data-aos-duration="800" className="border shadow-[0_0_10px_0_#64FFDA50] flex flex-col gap-2 border-primaryColor p-4 rounded-2xl sm-mx:p-2" id="Academia">
                <div className="flex gap-2 items-center">
                   <IconCheckbox className="rounded-lg w-16 md-mx:w-14 text-primaryColor text-bold text-4xl" />
                      <div className="flex flex-col">      
                    <div className="text-whiteColor text-2xl font-semibold md-mx:text-xl xs-mx:text-lg xsm-mx:text-base">{item.date}</div>
                      
                </div>
             </div>
               <div className="text-textColor leading-6 text-justify md-mx:text-sm xs-mx:text-xs">
                  &#x2022; {item.desc}
                </div>
                <div className="text-textColor leading-6 text-justify md-mx:text-sm xs-mx:text-xs">
                   {item.pesc}
                </div>
                           
 
           </div>
      </Timeline.Item>
)
  }

 const Academia=()=>{
  const size=useMatches({
      xs:15,
      md:20,

  })
  const dot=useMatches({
      xs:25,
      md:30,

  })

   return(
      <div  className="px-14 mx-20 md-mx:px-6 lg-mx:mx-0 sm-mx:px-2 my-10 mb-28 font-mono ">
      <h1 className="text-4xl sm-mx:text-3xl xs-mx:text-2xl mb-10 font-bold text-whiteColor text-center ">
       Academia</h1>
        <Timeline color="#64FFDA" active={8} bulletSize={dot} lineWidth={3}>
         {
           TimeLineItem(AcademicInfo)
         }          

      </Timeline>
           
     </div>
 )

}

export default Academia;
