import { socialLinks } from "../User"


const Social=()=>{

          const socialIcons=socialLinks.map((sociallink)=>{
          return <a href={`${sociallink.links}`} target="_blank" className="font-mono text-lg  
          hover:text-primaryColor hover:translate-x-2 transition-transform duration-300 
            ease-in-out">
         <div  data-aos="fade-down-left" data-aos-duration="800"><sociallink.icon size={30} className='-rotate-90' /></div>
        </a>
 
      })

 return(
  <div className="flex md-mx:hidden text-textColor fixed bottom-32 -left-40 rotate-90 items-center gap-10 lg:-left-44 md:-left-48">
         {socialIcons}
      <hr className="border-[2px] rounded-full text-textColor bg-textColor border-textColor w-40"/>
    </div>

 )

}
export default Social;

