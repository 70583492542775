import { useDisclosure } from '@mantine/hooks';
import { Drawer, Button } from '@mantine/core';
import { deflate } from 'zlib';
import { IconHexagonLetterV , IconX } from '@tabler/icons-react';
import { Burger } from '@mantine/core';
import { navLinks } from './Header';


  const SideBar=()=>{
  const [opened, { toggle }] = useDisclosure(false);

  return (

    <>
          
  
  <Drawer.Root className='md:hidden !-z-10' position='left' opened={opened} onClose={toggle} size="50vw" >
        <Drawer.Overlay className='!-z-0' style={{backgroundOpacity: 0.5,blur: 4}}/>Drawer
        <Drawer.Content className='!-z-0' bg="#0A192F">drawer
          <Drawer.Body className='mt-28  gap-5 flex flex-col' bg="#0A192F">
          {navLinks(true,toggle)}
           </Drawer.Body>
        </Drawer.Content>
      </Drawer.Root>

      
      <Burger className='md:!hidden !z-10' size='lg' color='#64FFDA' opened={opened} onClick={toggle}  />

    </>
  );
}

export default SideBar;
