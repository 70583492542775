import { Badge, Button, Card, Group, } from "@mantine/core";
import { Text,Image,Indicator } from '@mantine/core';
import FullServicesModal from "./FullServicesModal";
import { useDisclosure } from "@mantine/hooks";
import GalleryModal from "./GalleryModal";


 const GalleryCard = (props:any)=>{
  const [opened, {open,close}] = useDisclosure(false);
 
 return(
      
        <div className="w-[32%] lg-mx:w-[46%] md-mx:w-[48%] sm-mx:w-[90%] xs-mx:w-full" data-aos="fade-up" data-aos-duration="800">
     <Card onClick={open} className="!bg-bgColor cursor-pointer transition-transform
        duration-300 ease-in-out hover:!scale-[1.02] mb-5 hover:!shadow-[0_0_10px_1px_#64FFDA80]
          !border-primaryColor border-2" shadow="lg" padding="sm" radius="lg" withBorder>
      <Card.Section className="p-3" >
        <Image
           className="!rounded-xl !shadow-[0_0_5px_0_#64FFDA]"
          src={props.img}
          alt={props.img}
          onClick={open}
        />
      </Card.Section>

    </Card>
 
     <GalleryModal opened={opened} close={close} img={props.img}  />
  </div>


 )

}

export default GalleryCard;
