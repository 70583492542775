import {IconBrandFacebook, IconBrandInstagram,
        IconBrandLinkedin, IconBrandYoutube} from '@tabler/icons-react';

 const Info={
   name:"Vivek Pathak",
    stack:['an Academician',"a Researcher","an Edupreneur","a Social Innovator"],
     bio:"Result-driven management professional and entrepreneur with more than 17 years of academic, industry and entrepreneurship experience with accomplished research credentials with rich publication, mentoring and supervising research scholars."
 }
 


const socialLinks=[

     {links:"https://www.instagram.com" ,icon:IconBrandInstagram},
     {links:"https://www.facebook.com/drpathak.v",icon:IconBrandFacebook},
     {links:"https://in.linkedin.com/",icon:IconBrandLinkedin},
     {links:"https://www.youtube.com/@VEDPATHEducationalServices",icon:IconBrandYoutube}

];


  const ServiceInfo=[
    {
        title:"Vedpath Educational Services LLP",
         desc:"Vedpath Educational Services (VES) attempts to extend its expertise in various verticals of education and training society as a whole toward making their lives better in the future.Various educational services, platforms, and systems exist which are prevalent in society. It is something that cannot be ignored or deferred by a slight margin also",
         image:"https://i.pinimg.com/564x/5b/86/a8/5b86a866606f216db2b4c3e908e7585c.jpg",
         live: true,
         link:"http://vedpath.co.in",
         video:"https://www.youtube.com/watch?v=IwKBmA_tvs8",
     },
   
    {
        title:"Shivip Publications LLP",
         desc:"Shivip Publications LLP is a brain child of NIT Alumnus with sincere and focused aim of promoting academic as well as industry research among new generations at all levels. Our vision is to provide comprehensive support in research and publication. We provide an impetus towards dissemination of knowledge through our quality assistance programs to the authors, research scholars, corporates and various research seeking projects across industry verticals.",
         image:"https://i.pinimg.com/564x/66/36/69/6636694df990283e8298872f1ed9b641.jpg",
         live: true,
         link:"http://shivip.co.in",
         video:"https://www.youtube.com/watch?v=-v-blbeNm-w&list=PLWd_n7EmZlPvlSzTFuWU6jB_8_nXXiy-y",
     },
 
    {
       title:"Vedpath Consulting",
        desc:"Vedpath Consulting is a Research Consultancy initiative to put all the support resources in one place to enable the researchers save their quality time. We desire to assist the researchers in completing their research work in all possible ways.",
        image:"https://i.pinimg.com/564x/7e/6d/e6/7e6de693c128a3c3f5eaa9548cdbcfaf.jpg",
         live:true,
         link:"http://vedpathconsulting.in/",
         video:"https://www.youtube.com/watch?v=IwKBmA_tvs8",
         
      },
    
   {
        title:"Nonprofit Initiative",
         desc:"Dedicated for Economic, Social and Environmental Rural Transformation (DESERT) is a non-governmental organization (NGO) established on March 11, 2020, under the Charitable Trust Act of 1965. Based in Lucknow, Uttar Pradesh, DESERT focuses on rural development and poverty alleviation, aiming to uplift rural communities through comprehensive and sustainable initiatives.",
         image:"https://i.pinimg.com/564x/86/de/25/86de25bf5b2b497bb8be816e43e60bc0.jpg",
         live: true,
         link:"https://drvivekpathak.blogspot.com/2024/07/desert-ngo-profile-for-dedicated-for.html",
         video:"https://www.youtube.com/watch?v=w80ch3JCqHA",
     }
     

  ];



   const AcademicInfo = [
      {             
         date:"2006 -",
         desc:"Graduated with an MBA in Marketing from Abdul Kalam Technology Universty,Lucknow,UP,India.",
      },

       {
         date:"2007 - 2013",
         desc:"Began a dynamic carrer in the corporate sector,with role in marketing and sales across various industries.",
         
       },
       {
         date:"2013 - 2016",
          desc:"Trasitioned to academia,joining the National Institute of Technology,Silchar, Assam, as an Assistant Professor in the Department of Management Studies",
        },
       {
          date:"2016 - 2018",
           desc:"Continued to enrich his academic carrer by contributing as an Assistant Professor at NIT Rourkela,Odisha",
           pesc:" Earned a PhD in Management from NIT Silchar, with research focused on Managing Behavioural Intentions of Management Graduates in North-Eastern Universities of India.", 
        },
        {
          date:"2018 - 2019",
          desc:"Served as a Research Mentor at Accendere Knowledge Management Services, Bangalore, a subsidiary of CL Educate Ltd.",
        },
        {
           date:"2019 - 2021",
            desc:"Returned to NIT Rourkela as an Assistant Professor (Ad Hoc) in the School of Management, further strengthening his teaching and research profile",
         },
       {
          date:"2021 - 2024",
          desc:"Held the position of Associate Professor at Arunachal University of Studies",
       }
        
    ];

const slugs = [
  "typescript",
  "javascript",
  "dart",
  "java",
  "react",
  "flutter",
  "android",
  "html5",
  "css3",
  "nodedotjs",
  "express",
  "nextdotjs",
  "prisma",
  "amazonaws",
  "postgresql",
  "firebase",
  "nginx",
  "vercel",
  "testinglibrary",
  "jest",
  "cypress",
  "docker",
  "git",
  "jira",
  "github",
  "gitlab",
  "visualstudiocode",
  "androidstudio",
  "sonarqube",
  "figma",
];
  


export {Info}
export {AcademicInfo}
export {ServiceInfo}
export {slugs}
export {socialLinks}

